.outerGrid {
  border-bottom: .5px solid var(--color-gray);
  @media only screen and (max-width: 768px) {
    &:not(:last-child) {
      border-bottom: none;
    }
  }

  & > ion-row {
    & > ion-col:not(:last-child) {
      border-right: .5px solid var(--color-gray);
    }
    @media only screen and (max-width: 768px) {
      & > ion-col:not(:last-child) {
        border-right: none;
      }
    }
  }
}

.innerGrid {
  margin: 0;
  padding: 0;
  & > ion-row {
    border-bottom: none;
    @media only screen and (max-width: 768px) {
      border-bottom: .5px solid var(--color-gray);
    }
  }
}

.categoryLabel {
  font-size: 9px;
  color: var(--color-gray);
}

