$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  --background: #1d2936;
  .sideMenuItem {
    --background: #1d2936;
    --border-color: #263547;
    cursor: pointer;
  }
  .sideMenuItemTop {
    --background: #1d2936;
    --border-color: #263547;
    .icon {
      cursor: pointer;
    }
  }
  .icon {
    font-size: 22px;
  }
  .arrowIcon {
    color: #98999b;
  }
}
.className {
  font-size: 1em;
  margin: 10px 0 10px 20px;
}
.name {
  font-size: 1.1em;
  margin: 10px 0 10px 18px;
}
.identity {
  font-size: 12px;
  margin: 10px 0 10px 18px;
}

.avatar {
  font-size: 64px;
  margin-left: 10px;
}
.photo {
  margin-bottom: 25px;
  margin-left: 18px;
}
