.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .content {
    font-size: 16px;
    overflow-wrap: anywhere;
    white-space: pre-line;

    &.listItem {
      max-height: 96px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;

      b {
        background-color: #386ec6;
      }
    }

    &.light {
      color: #2a3b4d;
    }
    &.dark {
      color: #fff;
    }
  }

  .labelGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .experience {
      font-size: 12px;
      font-weight: bold;
      color: #ffff;
      border: solid 1px;
      padding: 4px 8px;
      border-radius: 15px;
      margin: 0;
    }

    .categoryWrapper {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-left: auto;
      align-content: center;
    }
  }

  .boxClassWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .area {
      display: flex;
      align-items: center;
      margin-right: 16px;
      font-size: 12px;
      color: #98999b;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }

  .footWrapper {
    display: flex;
    justify-content: flex-end;

    .reactionArea {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:not(:last-child) {
        margin-right: 12px;
      }

      .count {
        margin-left: 4px;
        color: #999;

        &__past {
          color: #808080;
        }
      }
    }

    .iconArea {
      position: relative;
      display: inline;
      margin-right: auto;
    }

    .icon {
      width: 18px;
      height: 18px;
      color: #999;

      &.unread {
        position: relative;

        &::before {
          content: "";
          width: 6px;
          height: 6px;
          position: absolute;
          top: 3px;
          right: 1px;
          border-radius: 50%;
          background-color: red;
        }
      }

      &.enabled {
        cursor: pointer;
      }

      &.active {
        color: #3dba9d;
      }
    }
  }
}
