.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  overflow: hidden;

  &.nowrap {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.label {
  margin-right: 8px;
  &:last-child {
    margin-right: 16px;
  }
}

.tag {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid #777e90;
  color: #777e90;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}

.directoryLabel {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  color: #fff;
  font-family: "Noto Sans JP";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;

  .icon {
    font-size: 12px;
  }
}
