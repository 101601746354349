.thumbnailWrapper {
  width: 100%;

  .thumbnailImage {
    max-height: 214px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;

    @media (min-width: 742px) {
      max-height: 320px;
    }
  }
}
