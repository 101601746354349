.header {
  background: #1d2936;
  position: fixed;
}
.toolBar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
  .title {
    --color: #fff;
  }
}
