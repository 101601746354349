.container {
  padding: 20px 50px;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .disabled {
    pointer-events: none;
  }

  .dropdown {
    width: 200px;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
  }

  .btnClear {
    height: 30px;
    font-size: 12px;
    --border-color: #00b6ce;
    --color: #00b6ce;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .dropdown {
      width: 100%;
    }
  }
}

.table {
  width: 100%;
  margin-top: 15px;

  @media (max-width: 768px) {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;

    .headerTitle,
    .titleCols {
      display: none !important;
    }

    .content {
      &:after,
      &:before {
        position: relative !important;
      }
    }
  }

  .headerTitle {
    background: linear-gradient(to left, #1d2936, #1e2c40, #00489b);
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 5px;
  }

  .content {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        #ccc 0%,
        #ddd 86%,
        #f9d682 92%,
        #cea962 100%
      );
    }

    .titleCols {
      display: flex;
      position: relative;
      z-index: 1;

      div {
        text-align: center;
        font-weight: bold;
        width: 45%;

        &:last-child {
          width: 10%;
          color: #fff;
        }
      }
    }

    .realizations {
      max-height: 730px;
      position: relative;
      overflow: auto;
      z-index: 1;

      @media (max-width: 768px) {
        overflow: initial;
        max-height: none;
      }

      .item {
        display: grid;
        grid-template-columns: 45% 45% 10%;
        column-gap: 3px;
        position: relative;
        overflow: hidden;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          height: 1px;
          width: 100%;
          background-color: #fff;
        }

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          background: linear-gradient(95.14deg, #f9d682 0%, #cea962 100%);
          border-radius: 10px;
          gap: 3px;
          box-shadow: 2px 2px 10px #00000070;
          margin-bottom: 20px;
        }

        .emptyBlock {
          background-color: #00000070;
          width: 100%;
          display: initial;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      .iconTransitionMark {
        position: absolute;
        left: calc(100% - 10px);
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        z-index: 3;

        @media (max-width: 768px) {
          top: calc(100% - 10px);
          left: 50% !important;
          transform: translateX(-50%) rotate(90deg) !important;
        }
      }

      .realizationContent {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 5px;
        margin: 3px 0;
        padding: 20px 10px 15px 20px;
        position: relative;

        &:first-child {
          margin-left: 3px;

          @media (max-width: 768px) {
            margin-left: 0;
          }
        }

        @media (max-width: 768px) {
          margin: 0;
          border-radius: 0;
        }

        .nameStudent {
          font-size: 13px;
          margin: 10px 0 15px;
        }

        .textContent {
          margin: 10px 5px 20px 0;
          font-size: 12px;
          white-space: pre-line;
          word-wrap: break-word;
        }

        .createAt {
          font-size: 10px;
          margin-top: auto;
          margin-bottom: 10px;
          color: #959595;
        }

        .footer {
          display: flex;
          align-items: flex-end;
          font-size: 10px;

          > div {
            &:nth-child(2) {
              margin-right: auto;
            }
          }

          .actionArea {
            display: flex;
            align-items: center;
          }

          .icon {
            font-size: 15px;
            margin: 0 5px;
            color: #54779c;
          }

          span {
            color: #54779c;
          }
        }
      }

      .expContent {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .iconTick {
          width: 45px;
          height: 45px;
          margin-top: 30px;
        }

        @media (max-width: 768px) {
          .iconTick {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
      }

      .label {
        padding: 0 5px;
        font-weight: bold;

        &__roots {
          border: 1px solid #3880ff;
          color: #3880ff;
          margin-left: 9px;
        }

        &__will {
          border: 1px solid #010a87;
          color: #010a87;
          margin-left: 9px;
        }

        &__exp {
          width: fit-content;
          font-size: 10px;
          border: 1px solid #fff;
          color: #fff;
          margin-bottom: 18px;

          @media (max-width: 768px) {
            position: absolute;
            right: 10px;
            bottom: 5px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.categoryArea {
  display: flex;
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;

  > div {
    max-width: 150px;
  }
}
