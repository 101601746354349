$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.fab {
  position: fixed;
  bottom: 12px;
  right: max(12px, (100% - 1000px) / 2 + 12px);
}
.fabButton {
  --background: #3dba9d;
  --background-focused: #3dba9d;
  --background-hover: #3dba9d;
  --background-activated: #3dba9d;
  --background-activated-opacity: 1.2;
}
.icon {
  font-size: 28px;
}
