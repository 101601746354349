$maxWidth: 1000px;
.container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: auto;
  min-height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  * {
    margin: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .layout {
    box-sizing: border-box;
    &:nth-child(1) {
      width: calc(640px / $maxWidth * 100%);
      border-right: solid 1px #243140;
      @media (max-width: 768px) {
        width: 100%;
      }
      .top {
        padding: 30px;
        border-bottom: solid 1px #243140;
        @media (max-width: 768px) {
          padding: 30px 15px;
        }
        .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .block:nth-of-type(1) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 15px;
        }
        .pieChart {
          position: relative;
          width: 60px;
          height: 60px;
          .rate {
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            width: 100%;
            color: #4a617c;
            font-family: Roboto;
            font-weight: 700;
            transform: translate(-50%, -50%);
            .count {
              color: #ffffff;
              font-size: 20px;
            }
            .line {
              display: inline-block;
              margin: 0 6px;
              width: 1px;
              height: 13px;
              background-color: #4a617c;
              transform: rotate(30deg);
            }
            .lenght {
              font-size: 14px;
            }
          }
        }
        .desc {
          margin-bottom: 5px;
          color: #fff;
          font-size: 24px;
          font-weight: 700;
          @media (max-width: 768px) {
            font-size: 20px;
          }
        }
        .title {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .inputArea {
        padding: 40px 30px 58px;
        @media (max-width: 768px) {
          padding: 40px 15px 0px;
        }
        .wrapper {
          margin-bottom: 40px;
          @media (max-width: 768px) {
            margin-bottom: 0;
          }
          .question {
            display: flex;
            justify-content: flex-start;
            gap: 8px;
            margin-bottom: 20px;
            .q {
              font-family: Roboto;
              color: #42af4d;
              line-height: 1.7;
              font-size: 14px;
              font-weight: 500;
            }
            .title {
              color: #fff;
              font-size: 16px;
              font-weight: 400;
            }
          }
          .aware {
            margin-bottom: 40px;
            .scoreText {
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              position: relative;
              margin: 0 auto 10px;
              padding: 20px;
              width: 100%;
              max-width: 550px;
              border-radius: 30px;
              background-color: #243140;
              color: #fff;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              &::after {
                content: "";
                position: absolute;
                bottom: -16px;
                width: 20px;
                height: 16px;
                clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
                background-color: #243140;
              }
            }
            .scoreList {
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              margin: 0 auto;
              max-width: 424px;
              height: calc(32px + 17px);
            }
            .score {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-direction: column;
              gap: 6px;
              width: 40px;
              height: 46px;
              @media (max-width: 768px) {
                width: 32px;
              }
              .lastAssessmentScore {
                display: inline-block;
                white-space: nowrap;
                padding: 1px 10px 2px;
                border: 1px solid #42af4d;
                text-align: center;
                color: #42af4d;
                border-radius: 30px;
                font-size: 10px;
                font-weight: 500;
              }
            }
            .direction {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 8px auto 0;
              max-width: 548px;
              span {
                color: #999999;
                font-size: 14px;
                font-weight: 500;
                @media (max-width: 768px) {
                  font-size: 12px;
                }
              }
            }
          }

          .reason {
            margin-bottom: 40px;
            @media (max-width: 768px) {
              margin-bottom: 0;
            }
            .hint {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              gap: 5px;
              box-sizing: border-box;
              margin-bottom: 10px;
              padding: 15px;
              width: 100%;
              border-radius: 5px;
              background: #111417;
              @media (max-width: 768px) {
                margin-bottom: 16px;
              }
              .block {
              }
              .icon {
                font-size: 20px;
                color: #999999;
              }
              .title {
                margin-bottom: 5px;
                text-align: left;
                color: #999;
                font-size: 14px;
                font-weight: 700;
              }
              .text {
                color: #999;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .nextAction {
        padding: 40px 30px 56px;
        @media (max-width: 768px) {
          padding: 40px 15px 0px;
        }
        .wrapper {
          margin-bottom: 50px;
          @media (max-width: 768px) {
            margin-bottom: 0;
          }
          .kindList {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .kind {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 8px;
              width: 100%;
              .block:nth-child(2) {
                flex: 1;
              }
              .q {
                display: block;
                font-family: Roboto;
                color: #42af4d;
                line-height: 1.8;
                font-size: 14px;
                font-weight: 500;
              }
              .question {
                margin-bottom: 20px;
                .shortTitle {
                  margin-bottom: 10px;
                  color: #fff;
                  font-size: 17px;
                  font-weight: 400;
                }
                .title {
                  color: #999;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }

      .pastComments {
        margin-top: 10px;
        background-color: #172029;
        @media (max-width: 768px) {
          margin-top: 20px;
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          border-bottom: solid 1px #243140;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          cursor: pointer;
          .icon {
            transition: 0.25s ease-out;
          }
        }
        &[open] .title {
          .icon {
            transform: rotate(180deg);
          }
        }
        .pastReasonList {
          .pastReason {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 15px;
            border-bottom: solid 1px #243140;
            .date {
              color: #4a617c;
              font-family: Roboto;
              font-size: 12px;
              font-weight: 400;
            }
            .text {
              color: #fff;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      padding: 30px;
      width: calc(360px / $maxWidth * 100%);
      @media (max-width: 768px) {
        padding: 30px 15px;
        width: 100%;
      }
      .stock {
        .title {
          margin-bottom: 25px;
          font-size: 17px;
          color: #fff;
        }
        .wrapper {
          overflow-y: auto;
          box-sizing: border-box;
          max-height: calc(100vh - 80px);
          word-wrap: break-word;
          @media (max-width: 768px) {
            margin-bottom: 40px;
            padding: 0 15px;
            width: 100%;
            max-height: 300px;
          }
        }
        .realizationList {
          display: flex;
          flex-direction: column;
          gap: 30px;
          .realization {
            color: #ffffff;
            .inner {
              display: flex;
              flex-direction: column;
              gap: 12px;
            }
            .header {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 12px;
              .tag {
                display: flex;
                align-items: center;
                padding: 3px 12px 2px 12px;
                border-radius: 2px;
                color: #fff;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 700;
              }
              .date {
                color: #999;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
              }
            }
            .content {
              color: #999;
              font-size: 14px;
              font-weight: 300;
            }

            .origin {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              gap: 4px;
              margin-top: 10px;
              .inner {
                padding: 8px;
                width: 100%;
                border: 1px solid #666;
                border-radius: 4px;
              }
              .header {
                .tag {
                  opacity: 0.4;
                }
                .date {
                  color: #666;
                }
              }
              .content {
                color: #666;
              }
            }
          }
        }
      }

      .inputContents {
        .title {
          margin-bottom: 20px;
          color: #fff;
        }
        .wrapper {
          box-sizing: border-box;
          overflow-y: auto;
          max-height: calc(100vh - 310px);
          @media (max-width: 768px) {
            margin-bottom: 40px;
            width: 100%;
            max-height: 300px;
            padding: 0 15px;
          }
        }
        .barChart {
          margin-bottom: 30px;
        }
        .assessmentExperienceList {
          display: flex;
          flex-direction: column;
          gap: 30px;
          .assessmentExperience {
            .experience {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 5px;
              margin-bottom: 10px;
              color: #ffffff;
              .border {
                display: inline-block;
                width: 5px;
                height: 20px;
                border-radius: 5px;
              }
            }
            .reasonList {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .reason {
                .text {
                  color: #fff;
                  font-size: 14px;
                  font-weight: 300;
                }
                .date {
                  display: block;
                  margin-top: 10px;
                  text-align: right;
                  color: #999;
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }

  .buttonWrapper {
    justify-content: space-between;
    &.isPc {
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &.isSp {
      display: none;
      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
}
