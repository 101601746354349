$maxWidth: 1000px;

.container {
  box-sizing: border-box;
  padding: 43px 30px 47px;
  height: auto;
  min-height: 100%;
  @media (max-width: 768px) {
    padding: 30px 0 43px;
  }

  * {
    margin: 0;
  }

  .assessment {
    margin-bottom: 30px;
    @media (max-width: 768px) {
      padding: 0 15px;
    }
    .title {
      margin-bottom: 22px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      @media (max-width: 768px) {
        margin-bottom: 15px;
        font-size: 18px;
      }
    }
    .list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 20px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .chart {
    display: flex;
    justify-content: center;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
    .barChart {
      width: calc(620px / ($maxWidth - 60px - 10px) * 100%);
      height: 375px;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
    .pieChart {
      width: calc(300px / ($maxWidth - 60px - 10px) * 100%);
      height: 375px;
      @media (max-width: 768px) {
        width: 100%;
        height: 337px;
      }
    }
  }
}
