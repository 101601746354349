.tabs {
  &__header {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    position: relative;
    margin-top: 20px;
    padding: 0 20px;
    border-bottom: 0;
    z-index: 2;

    > div {
      flex-shrink: 0;
      position: relative;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      text-decoration: none;
      background-color: #959595;
      border-radius: 4px 4px 0px 0px;
      height: 34px;
      filter: drop-shadow(-1px -4px 3px rgba(0, 0, 0, 0.2));
      cursor: pointer;

      .wrapper_title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        padding: 0 16px;
        height: 100%;
      }

      p {
        margin: 0;
        white-space: pre-line;
        text-align: center;
      }

      &:global(.active) {
        background-color: #00b6ce;
        z-index: 2;
      }

      &:hover {
        background-color: #008dce;
      }

      svg {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
        fill: #fff;
      }
    }
  }

  &__content {
    min-height: 100px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.44);
    background-color: #fff;
    margin-bottom: 20px;
  }
}
