.iconCounter {
  background-color: #131b24;
  text-align: center;
  width: 33%;
}

.counter {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
.icon {
  margin: 10px;
  font-size: 32px;
}

.title {
  font-size: 12px;
  color: #ddd;
}

.border {
  border-right: 1px solid #888;
}
