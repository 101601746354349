$mainBackgroundColor: #131b24;

.wrapper {
  background-color: $mainBackgroundColor;

  &__past {
    background-color: #484d52;
  }

  .title {
    color: #7c8085;
    font-size: 12px;
    margin-bottom: 8px;
    margin-left: 15px;
    padding-top: 8px;
  }
  .item {
    --background: $mainBackgroundColor;
    --color: #7c8085;
    border-bottom: 1px solid #7c8085;
    margin-left: 10px;
    margin-right: 10px;
  }
  .loadMore {
    padding: 5px 0 10px;
    color: #fff;
    text-align: center;
  }
}
