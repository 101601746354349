$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
  overflow: hidden;
  box-sizing: border-box !important;
  --background: #131b24;

  @media (min-width: 767px) {
    max-width: 680px;
    max-height: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.realizationItem {
  --background: #131b24;
  --detail-icon-color: #98999b;
  --padding-start: 10px;
  --padding-top: 12px;
  border-bottom: solid 1px #323538;
  width: 100%;
  overflow: auto;
  padding: 10px 10px 0;
  box-sizing: border-box;
  margin-top: 44px;
}

.name {
  color: #fff;
  font-size: 16px;
}

.container {
  width: 100%;
  min-height: 68px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: #131b24 linear-gradient(rgba(19, 27, 36, 0.9) 0%, #131b24 5%, #131b24 100%);

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 16px;
    position: absolute;
    margin-top: -16px;
    background: transparent;
    background: linear-gradient(rgba(19, 27, 36, 0) 0%, rgba(19, 27, 36, 0.9) 100%);
  }

  @media (min-width: 767px) {
    bottom: 0;
  }
}

.contentWrapper {
  display: flex;
}

.avatarArea {
  position: relative;
}

.avatar {
  position: absolute;
  top: 0;
  margin-top: 5px;
  width: 50px;
  height: 50px;
}

.row {
  margin-left: 55px;
}

.iconArea {
  float: right;
}

.reportIcon {
  float: right;
  margin-right: 7px;
  color: #69696b;
  cursor: pointer;
}

.header {
  background: #1d2936;
  position: fixed;
  top: 0;
}

.toolBar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;

  .title {
    --color: #fff;
  }
}

.modal {
  --background: #131b24;
  height: 89%;
}

.contentComment {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
