$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;
$headerHeight: 44px;
$roots: #3e9eff;
$will: #195cfb;
$wrapperButtonHeight: 80px;
$breakPoint: 1000px;
$breakPointMiddle: 768px;
$breakPointSmall: 450px;

.wrapper {
  --background: #131b24;
  --color: #fff;
  height: 100%;

  .containerWrapper {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - $headerHeight - $wrapperButtonHeight);
    padding-top: $headerHeight;
    background: #131b24;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .wrapperButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: $wrapperButtonHeight;
    background-color: #19222b;
    position: fixed;
    bottom: 0;
    z-index: 1;

    button {
      max-width: 168px;
      min-width: 152px;
      width: 30%;
      height: 52px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      padding: 5px 15px 5px 10px;
      background: none;
      border: 2px solid;
      border-radius: 5px;
      margin: 0 9px;
      color: #fff;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }

      &.createRealization {
        border-color: $roots;

        .icon {
          height: 40px;
          width: 40px;
          margin-right: 5px;
          color: $roots;
        }
      }

      &.createConversion {
        border-color: $will;

        .icon {
          height: 32px;
          width: 32px;
          margin: 8px 13px 8px 8px;
          color: $will;
        }
      }

      span {
        margin: auto;
        line-height: 18px;
      }
    }
  }

  .textAreaWrapper {
    max-height: 250px;
    overflow-y: auto;
  }

  .inputArea {
    background-color: #2a3139;
    margin: 10px 10px 0px;
    border-radius: 5px;
  }

  .imgInputWrapper {
    display: flex;
    padding: 0 8px 8px;

    .realizationImg {
      display: flex;
      margin: 0 auto;
      position: relative;
      cursor: zoom-in;

      > button {
        padding: 0;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        color: #fff;
        background: #2a3139;
        cursor: pointer;
        outline: none;
        border: none;
        transition: 0.3s;
        position: absolute;
        top: 8px;
        right: 8px;
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2);
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    }

    > input {
      display: none;
    }

    > svg {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .counter {
    text-align: right;
    margin-right: 10px;
  }

  .actions {
    display: flex;
    padding: 0 20px;
  }
}

.feedbackButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: auto;
  padding: 0 24px 24px;
  .text {
    font-size: 12px;
    color: #ffffff;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 144px;
    height: 34px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    background-color: #16bab6;
    color: #ffffff;
    border: none;
  }
}

.feedbackModal {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 660px;
  height: calc(100% - 20px);
  max-height: 550px;
  background-color: #ffffff;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  @media (max-width: $breakPointSmall) {
    max-height: calc(100% - 20px);
  }

  .close {
    overflow-y: auto;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 22px;
    height: 22px;
    pointer-events: auto;
    cursor: pointer;
    @media (max-width: $breakPointSmall) {
      top: 8px;
      right: 8px;
    }
    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 100%;
      background-color: #24292e;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .callingMessage {
    font-size: 18px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
    padding: 0 60px;
    width: 100%;
    height: calc(100% - (44px * 2));
    @media (max-width: $breakPointSmall) {
      padding: 0 32px;
    }
  }

  .title {
    margin: 0;
    text-align: center;
    color: #19d1cc;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.6;
  }

  .content {
    margin: 0;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 1.6;
  }

  .date {
    margin: 0;
    text-align: center;
    font-size: 12px;
  }
}
