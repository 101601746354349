.wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  flex-wrap: wrap;

  .text {
    font-size: 14px;
    line-height: 16px;
    color: #999;
  }

  .date {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
