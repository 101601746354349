.realization {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  box-sizing: border-box;
  padding: 30px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #111417;
  @media (max-width: 768px) {
    gap: 25px;
    padding: 26px 20px 26px;
  }
  .title {
    width: 100%;
    text-align: left;
    color: #4a617c;
    font-size: 17px;
    font-weight: 500;
  }
  .chart {
    position: relative;
    width: 200px;
    height: 200px;
    .stock {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      font-family: "Roboto", sans-serif;
      transform: translate(-50%, -50%);
    }
    .count {
      color: #fff;
      font-size: 44px;
      font-weight: 500;
    }
    .text {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
