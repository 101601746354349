.textArea {
  min-height: 96px; // for re-rendering after submit
  color: #fff;
  display: block;
  width: 100%;
  resize: none;
  border: 0;
  outline: none;
  background: transparent;
  box-sizing: border-box;
  appearance: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  textarea {
    min-height: 96px; // for re-rendering after submit
    padding: 12px 15px !important;

    @media (max-width: 400px) {
      padding: 12px 12px !important;
    }
  }
}

.textArea::-webkit-scrollbar {
  display: none;
}
