$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  background-color: #131b24;
  height: 120%;
}

.inactive {
  color: #777;
}

.segmentButton {
  --indicator-color: #3dba9d;
  --color-checked: #3dba9d;
}
.chart {
  text-align: center;
}
