.modal {
  width: 100%;
  height: calc(100% - 72px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box !important;
}

.backdrop {
  width: 100%;
  height: calc(100% - 72px);
  background-color: #131b2467;
  box-sizing: border-box !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
