.wrapper {
  padding-left: 6px;
  color: #98999b;
  border-left: 3px solid #98999b;
  max-width: 200px;

  &.verticalMargin {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;

    &.fontS {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
