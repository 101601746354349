.labelArea {
  position: relative;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 92%;
  font-size: 15px;
  background-color: #292f3c;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  .label {
    margin-left: 10px;
  }
  .arrowIcon {
    position: absolute;
    top: 19px;
    right: 10px;
    color: #fff;
    height: 15px;
    width: 20px;
  }
}
.isOpen {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.menu {
  margin: 0 auto;
  background-color: #292f3c;
  padding-left: 0;
  width: 92%;
  max-height: 200px;
  overflow-x: auto;
  list-style: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.options {
  position: relative;
  color: #fff;
  width: 100%;
  border-top: solid 1px #2f394b;
  padding-top: 15px;
  padding-bottom: 15px;
}
.optionLabel {
  position: relative;
  margin-left: 10px;
  font-size: 15px;
  .dot {
    color: red;
    position: absolute;
    bottom: 12px;
    font-size: 12px;
  }
}
.checkIcon {
  position: absolute;
  right: 10px;
  top: 13px;
  color: #3dba9d;
  height: 20px;
  width: 20px;
}

.styleTeacherApp {
  > div {
    background-color: #eaeaea;
    color: #292f3c;

    ion-icon {
      fill: #292f3c;
    }
  }
  ul {
    background-color: royalblue;
    background-color: #eaeaea;
    li {
      color: #292f3c;
      border-top: 1px solid #fff;
    }
  }
}
