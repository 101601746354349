.container {
  box-sizing: border-box;
  padding: 100px 30px;

  * {
    margin: 0;
    padding: 0;
  }

  .title {
    margin-bottom: 12px;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
  }

  .desc {
    margin-bottom: 32px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }

  .pieChartWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .pieChart {
    position: relative;
    width: 60px;
    height: 60px;
    .rate {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      width: 100%;
      color: #4a617c;
      font-family: Roboto;
      font-weight: 700;
      transform: translate(-50%, -50%);
      .count {
        color: #ffffff;
        font-size: 20px;
      }
      .line {
        display: inline-block;
        margin: 0 6px;
        width: 1px;
        height: 13px;
        background-color: #4a617c;
        transform: rotate(30deg);
      }
      .lenght {
        font-size: 14px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 56px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .button:nth-of-type(1) {
    background-color: #3ebb9d;
    color: #ffffff;
    border: none;
  }
  .button:nth-of-type(2) {
    border: 1px solid #3ebb9d;
    background-color: transparent;
    color: #3ebb9d;
  }

  .refers {
    margin-top: 24px;
    color: #3ebb9d;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
  }
}
