$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  --width: 100%;
  --height: 100%;
  --background: rgba(255, 255, 255, 0.5);
  --border-radius: 0;

  .modal {
    width: 90%;
    max-width: 500px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%);
    border-radius: 12px;
    background-color: $subBackgroundColor;
    overflow: hidden;
  }

  .label {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 50px;
    white-space: pre-line;
    text-align: center;
    color: #fff;

    p {
      font-size: 12px;

      @media (min-width: 742px) {
        font-size: 14px;
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    border-top: solid 1px #363a3e;

    .btn {
      --background: #1d2936;
      --border-radius: 0;
      --background-activated: #353e47;
      --background-focused: #353e47;
      --background-hover: #353e47;
      height: 50px;
      position: relative;
      margin: 0;

      &:not(:last-child) {
        &:after {
          content: "";
          height: 80%;
          width: 1px;
          background-color: #363a3e;
          position: absolute;
          top: 10%;
          right: 0px;
        }
      }

      &--1 {
        width: 100%;
      }

      &--2 {
        width: 50%;
      }

      &--3 {
        width: calc(100% / 3);
      }
    }

    .Success {
      --color: #3dba9d;
    }

    .Warning {
      --color: #ffc107;
    }

    .Danger {
      --color: red;
    }

    .None {
      --color: #fff;
    }
  }
}
