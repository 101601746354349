.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ion-select::part(text) {
  color: #3dba9d !important;
}
ion-select::part(icon) {
  color: #3dba9d !important;
}
.select-interface-option {
  --color: #eee;
}
.select-popover {
  --ion-background-color: #131b24;
}

body {
  overflow: hidden;
}

input,
textarea {
  font-size: initial !important;
}

body {
  background-color: #131b24;
}

html,
body,
ion-app,
ion-content,
.nav-decor {
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -ms-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}

html {
  touch-action: manipulation;
}

@media only screen and (min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #153877;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #112d62;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  width: 100%;
  padding-top: 0 !important;
  transform: none !important;
}

.react-datepicker__header {
  width: 100%;
  background-color: transparent !important;
  border-bottom: 0 !important;
}

.react-datepicker__current-month {
  color: #fff !important;
}

.react-datepicker {
  width: 100%;
  background-color: rgba(43, 60, 80, 0.8) !important;
  border: 0 !important;
}

.react-datepicker__month {
  background-color: transparent;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.4rem !important;
  line-height: 1.4rem !important;
  color: #fff !important;
}

.react-datepicker__day-name {
  font-size: 10px;
}

.react-datepicker__day {
  border-radius: 50% !important;
  font-size: 11px !important;
  outline: none;
}

.react-datepicker__day:hover {
  background-color: #2579ba !important;
}

.react-datepicker__day--today:focus-visible {
  outline: 0;
}

.react-datepicker__day--in-range {
  background-color: transparent !important;
  position: relative;
  box-sizing: content-box;
  z-index: 1;
}

.react-datepicker__day--in-range::before {
  content: "";
  background-color: #3880ff;
  position: absolute;
  top: 0;
  right: -0.166rem;
  bottom: 0;
  left: -0.166rem;
  z-index: -1;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  position: relative;
}

.react-datepicker__day--range-start::before,
.react-datepicker__day--in-range:first-child::before {
  left: 0;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.react-datepicker__day--range-end::before,
.react-datepicker__day--in-range:last-child::before {
  right: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-datepicker__day--range-start::after,
.react-datepicker__day--range-end::after {
  content: "";
  background-color: transparent;
  position: absolute;
  inset: 0;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 1;
}
