.wrapper {
  padding: 24px 16px 12px;

  button {
    margin-right: 4px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #242b34;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #2c3948;
    }

    &.active {
      border-bottom: solid 2px #fff;
      background-color: #2c3948;
    }
  }
}
