.button {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  &.return {
    color: #3ebb9d;
    border: 1px solid #3ebb9d;
  }
  &.save {
    background-color: #3ebb9d;
    color: #fff;
  }
}
