$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.header {
  background: #1d2936;
  position: fixed;
}
.toolbar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
  .title {
    --color: #fff;
  }
  .menuIcon {
    color: #fff;
  }
  .clearButton {
    --color: #3dba9d;
  }
}
.mark {
  position: absolute;
  top: 5px;
  left: 20px;
  font-size: 10px;
  color: #eb445a;
}
