$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.tabs {
  background: #1d2936;
}
.tabBar {
  width: 100%;
  height: 72px;
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  gap: 4px;
  --color-selected: #3dba9d !important;

  @media (min-width: 742px) {
    flex-direction: row;
  }
}
.icon {
  color: #fff;
}
.badge {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #eb445a;
  position: absolute;
  top: 8px;
  left: auto;
  right: calc(50% - 24px);

  @media (min-width: 742px) {
    top: 16px;
    left: calc(50% - 36px);
    right: auto;
  }
}
.tabLabel {
  @media (min-width: 742px) {
    margin-left: 8px;
    font-size: 15px;
    font-weight: 600;
  }
}
