.wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  flex-wrap: wrap;

  .iconWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
      width: 18px;
      height: 18px;
      color: #999;
    }
  }

  .text {
    font-size: 14px;
    line-height: 16px;
    color: #999;
  }

  .date {
    display: flex;
    align-items: center;
    gap: 8px;

    .arrowIcon {
      width: 12px;
      height: 12px;
      color: #999;
    }
  }
  .checkedStatus {
    color: #999;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-left: auto;

    .checkIcon {
      width: 16px;
      height: 16px;
      margin-right: 1px;
    }
  }
}
