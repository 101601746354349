$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  background-color: $mainBackgroundColor;

  .loadMore {
    padding: 5px 0 10px;
    color: #fff;
    text-align: center;
  }
}
