$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;
$roots: #3e9eff;
$will: #195cfb;
$wrapperButtonHeight: 80px;
$footerHeight: 72px;

.wrapper {
  height: 100%;
  background-color: #131b24;
  position: relative;

  .container {
    width: 100%;
    height: calc(100% - $wrapperButtonHeight);
    background: #131b24;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .header {
    background: #1d2936;
  }
  .toolbar {
    max-width: 1000px;
    margin: 0 auto;
    --background: transparent;
    .title {
      --color: #fff;
    }
    .menuIcon {
      color: #fff;
    }
    .configButton {
      --color: #fff;
    }
  }
  .item {
    --background: #131b24;
    --border-color: #263547;
    .conversionIcon {
      display: block;
      margin: 0 auto;
      font-size: 40px;
      color: #808080;
    }
    .textarea {
      max-height: 100px;
      --placeholder-color: #fff;
      --padding-start: 0px;
      caret-color: #3dba9d;
      color: #fff;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .input {
      --placeholder-color: #fff;
      caret-color: #3dba9d;
      color: #fff;
    }
    .date {
      color: #98999b !important;
    }
  }
  .imgInputWrapper {
    display: flex;
    padding: 0 8px 8px;

    .realizationImg {
      display: flex;
      margin: 0 auto;
      position: relative;
      cursor: zoom-in;

      > button {
        padding: 0;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        color: #fff;
        background: #2a3139;
        cursor: pointer;
        outline: none;
        border: none;
        transition: 0.3s;
        position: absolute;
        top: 8px;
        right: 8px;
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2);
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
    > input {
      display: none;
    }
    > svg {
      margin-left: auto;
      cursor: pointer;
    }
  }
  .wrapperButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: $wrapperButtonHeight;
    background-color: #19222b;
    position: fixed;
    bottom: $footerHeight;
    z-index: 1;

    button {
      max-width: 168px;
      min-width: 152px;
      width: 30%;
      height: 52px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      padding: 5px 15px 5px 10px;
      background: none;
      border: 2px solid;
      border-radius: 5px;
      margin: 0 9px;
      color: #fff;
      cursor: pointer;

      &:disabled {
        pointer-events: none;
        opacity: 0.8;
      }

      .icon {
        margin-right: 5px;
      }

      &.createRealization {
        border-color: $roots;

        .icon {
          height: 40px;
          width: 40px;
          margin-right: 5px;
          color: $roots;
        }
      }

      &.createConversion {
        border-color: $will;

        .icon {
          height: 32px;
          width: 32px;
          margin: 8px 13px 8px 8px;
          color: $will;
        }
      }

      span {
        margin: auto;
        line-height: 18px;
      }
    }
  }

  .gearThemeWrapper {
    display: flex;
    align-items: center;
    color: #fff;
    margin: 12px 10px 0;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
.toast {
  --background: #3dba9d;
  --color: #fff;
}
.border {
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: solid #323538 1px;
}
.counter {
  text-align: right;
  margin-right: 10px;
}
