$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.realizationItem {
  position: relative;
  --background: #131b24;
  --background-hover: #131b24;
  --border-color: #263547;
  --detail-icon-color: #98999b;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  &__past {
    --background: #484d52;
  }

  .avatarArea {
    position: absolute;
    top: 14px;

    .avatar {
      margin-top: 0px;
      height: 45px;
      width: 45px;
    }
  }

  .grid {
    margin-left: 52px;
    overflow: hidden;

    .wrapperContent {
      width: 100%;
      display: flex;
      align-items: center;

      .container {
        width: calc(100% - 20px);

        .topWrapper {
          width: 100%;
          display: flex;
          margin-bottom: 4px;
          justify-content: space-between;

          .nameArea {
            width: 50%;
            padding: 5px;

            .name {
              color: #fff;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }

      .iconCaretRight {
        margin-left: auto;
        width: 20px;
        height: 20px;
      }
    }
  }
}
