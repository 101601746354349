.radioButton {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;

  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }

  .check {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
    border-radius: 100%;
    z-index: 1;
    transition: border 0.25s ease-out;
    transform: translate(-50%, -50%);
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      margin: auto;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      transition: background 0.25s ease-out;
    }
  }

  input[type="radio"]:checked ~ .check {
    border: 2px solid #3ebb9d;
  }

  input[type="radio"]:checked ~ .check::before {
    background: #3ebb9d;
  }
}
