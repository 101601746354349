.wrapper {
  background-color: #131b24;

  .item {
    --background: #131b24;
    --border-color: #263547;
    --detail-icon-color: #98999b;
    --padding-start: 8px;

    .grid {
      margin-top: 6px;
      margin-bottom: 6px;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
      }

      .footer {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        color: #98999b !important;

        span {
          font-size: 16px;
          text-align: justify;

          &:last-child {
            font-size: 13px;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .emptyData {
    color: #fff;
    text-align: center;
  }
}
