$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  background-color: #131b24;
  .box {
    margin: 20px 0;
  }
  .all {
    margin-bottom: 20px;
    margin-top: 20px;
    --background: #1d2936;
    --detail-icon-color: #98999b;
    --border-color: #263547;
    cursor: pointer;
  }
  .categories {
    --background: #1d2936;
    --detail-icon-color: #98999b;
    --border-color: #263547;
    cursor: pointer;
  }
  .categoryLabel {
    margin-top: 13px;
    margin-bottom: 13px;
  }
}
