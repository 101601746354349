$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.styleTeacherApp {
  --background: #fff !important;
  .stampCommentArea {
    .name {
      color: #2b343e !important;
    }
  }
}
.wrapper {
  z-index: 0;
  margin-top: 5px;

  &:first-child {
    margin-top: 20px;
  }

  &__past {
    --background: #484d52;
  }

  --padding-start: 0;
  .currentCommentWrapper {
    width: 100%;
    .currentInfo {
      float: right;
      color: #69696b;
      font-size: 12px;
    }
    .currentContent {
      margin-left: auto;
      width: 65%;
      max-width: 65%;
      min-height: 20px;
      padding: 15px 10px;
      font-size: 16px;
      color: #fff;
      background-color: #31445c;
      border-radius: 15px;
      border-bottom-right-radius: 0;
      overflow-wrap: anywhere;
      white-space: pre-line;
      word-break: break-all;
    }
    .stampCommentArea {
      background-color: transparent;
    }
  }
  .wrapperMsg {
    width: 65%;
    max-width: 65%;
  }
  .commentArea {
    height: auto;
    min-height: 60px;
    padding: 5px;
    background-color: #2b343e;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    .name {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      margin-top: 2px;
      margin-left: 7px;
    }
    .content {
      width: 95%;
      margin-top: 2px;
      margin-left: 7px;
      margin-bottom: 5px;
      font-size: 16px;
      color: #fff;
      overflow-wrap: anywhere;
      white-space: pre-line;
      word-break: break-all;
    }

    &.anonymous {
      min-height: 30px;
    }
  }

  .stampCommentArea {
    background-color: transparent;
  }

  .date {
    color: #69696b;
    font-size: 12px;
  }
  .avatarArea {
    &.clicableAvatar {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .avatar {
      width: 45px;
      height: 45px;
      margin: 0 5px;
    }
  }
  .reportIcon {
    float: right;
    margin-right: 7px;
    color: #69696b;
    cursor: pointer;
  }
  .configIcon {
    float: right;
    margin-right: 7px;
    margin-top: 3px;
    color: #69696b;
    cursor: pointer;
  }
}
.systemComment {
  font-weight: bold;
}

.myStampWrapper {
  display: flex;
  justify-content: flex-end;
}

.otherStampWrapper {
  display: flex;
  justify-content: flex-start;
}

.stamp {
  width: 152px;
}