$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
  overflow: hidden;
  box-sizing: border-box !important;
  --background: #131b24;

  &__past {
    background-color: #484d52;
  }

  @media (min-width: 767px) {
    max-width: 680px;
    max-height: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.realizationItem {
  --background: #131b24;
  --detail-icon-color: #98999b;
  --padding-start: 10px;
  --padding-top: 12px;
  border-bottom: solid 1px #323538;
  width: 100%;
  overflow: auto;
  padding: 10px 10px 0;
  box-sizing: border-box;
  margin-top: 44px;

  &__past {
    --background: #484d52;
  }
}
.name {
  color: #fff;
  font-size: 16px;
}

.container {
  width: 100%;
  min-height: 68px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background-color: #131b24;
  background: linear-gradient(
    rgba(19, 27, 36, 0.9) 0%,
    #131b24 5%,
    #131b24 100%
  );

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 16px;
    position: absolute;
    margin-top: -16px;
    background: transparent;
    background: linear-gradient(
      rgba(19, 27, 36, 0) 0%,
      rgba(19, 27, 36, 0.9) 100%
    );
  }

  @media (min-width: 767px) {
    bottom: 0;
  }
}
.inputArea {
  display: flex;
  width: 95%;
  margin: 10px auto;
  border-radius: 30px;
  border: solid 2px #323538;
  background-color: #21272e;

  .input {
    padding-top: 15px;
    margin-left: 15px;
    --placeholder-color: #fff;
    caret-color: #3dba9d;
    color: #fff;
    display: block;
    width: 100%;
    max-width: 100%;
    resize: none;
    border: 0;
    outline: none;
    background: transparent;
    box-sizing: border-box;
    appearance: none;
  }
  .cancelButton {
    width: 60px;
    font-size: 12px;
    margin-top: 10px;
    --background: #21272e;
    --background-activated: #21272e;
    --background-opacity: 1.2;
    --color: #3dba9d;
  }
  .commentButton {
    margin-bottom: 2px;
    vertical-align: middle;
    margin-right: 10px;
    --background: #21272e;
    --color: #3dba9d;
    --box-shadow: none;
  }
}

.contentWrapper {
  display: flex;
}
.avatarArea {
  position: relative;
}
.avatar {
  position: absolute;
  top: 0;
  margin-top: 5px;
  width: 50px;
  height: 50px;
}
.commentList {
  overflow: auto;
  padding-bottom: 82px;
}

.row {
  margin-left: 55px;
}
.iconArea {
  float: right;
}

.reportIcon {
  float: right;
  margin-right: 7px;
  color: #69696b;
  cursor: pointer;
}

.header {
  background: #1d2936;
  position: fixed;
  top: 0;
}

.toolBar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
  .title {
    --color: #fff;
  }
}

.contentComment {
  --background: var(--ion-background-color);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
