.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  box-sizing: border-box;
  padding: 108px 80px 148px;
  height: auto;
  min-height: 100%;
  @media (max-width: 768px) {
    padding: 88px 40px 128px;
  }

  * {
    margin: 0;
  }

  ol,
  ul {
    list-style-type: none;
    padding: 0;
  }
}

.setion {
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 48px;
  color: #fff;
  text-align: center;
  .main {
    font-size: 32px;
    font-weight: 500;
  }
  .sub {
    font-size: 16px;
  }
}

.location {
  .map {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    box-sizing: border-box;
    padding: 64px 100px 80px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #4a617c;
    background-color: #172029;
    @media (max-width: 768px) {
      gap: 24px;
      padding: 32px 20px 40px;
    }
    .image {
      width: 100%;
      max-width: 550px;
    }
    .text {
      max-width: 640px;
      color: #fff;
      text-align: center;
      font-size: 16px;
    }
  }
}

.sevenStatus {
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    .item {
      box-sizing: border-box;
      padding: 40px;
      width: calc((100% - 24px) / 2);
      border-radius: 4px;
      border: 1px solid #4a617c;
      background-color: #172029;
      @media (max-width: 768px) {
        width: 100%;
      }
      .desc {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
      .title {
        margin-top: 4px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }
      .text {
        margin-top: 16px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.assessmentAxis {
  display: flex;
  flex-direction: column;
  align-items: center;
  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 560px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 10px;
      width: 8px;
      height: 100%;
      background-image: url("../../../assets/images/Assessment/assessmentAxis_line.svg");
      background-size: cover;
      @media (max-width: 437px) {
        width: 9px;
      }
    }
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 32px;
      width: 100%;
      .no {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-width: 28px;
        min-height: 28px;
        border-radius: 50%;
        background-color: #3ebb9d;
        color: #141a21;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
      }
      .labelWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
        padding-bottom: 24px;
        text-align: left;
        border-bottom: 1px solid #4a617c;
      }
      .label {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
      }
      .summary {
        text-align: left;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.return {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 252px;
  height: 56px;
  border-radius: 4px;
  background-color: #3ebb9d;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
