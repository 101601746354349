$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  background-color: #1d2936;
  max-width: 500px;
  margin: auto;
  padding-bottom: 20px;
  .topTitle {
    text-align: center;
    margin-top: 60px;
    color: #fff;
    font-size: 18px;
  }

  .inputArea {
    position: relative;
    margin: 35px 40px 0;
    font-size: 14px;
    .input {
      --background: #fff;
      margin-top: 5px;
      margin-bottom: 10px;
      position: relative;
    }
    .eyesIcon {
      z-index: 2;
      position: absolute;
      margin-top: -37px;
      right: 10px;
      color: #000;
    }
  }

  .loginButton {
    margin: auto;
    margin-top: 28px;
    --background: #3dba9d;
    --background-activated: #3dba9d;
    --border-radius: 5px;
    color: #fff;
    font-weight: bold;
    max-width: 200px;
    font-size: 16px;
  }

  .text {
    margin-top: 32px;
    text-align: center;
    font-size: 14px;
  }
}

.invalidFeedback {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #d84429;
}

.btnArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 28px;
  margin-top: 32px;
  button {
    display: flex;
    -webkit-box-align: center;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    outline: 0;
    font-weight: 700;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 0 10px;
    font-size: 18px;
    border-radius: 5px;
    color: #fff;
    width: 200px;
    border: none;
    svg {
      height: 24px;
      width: 40px;
      margin-right: 10px;
      padding-right: 8px;
      border-right: 2px solid rgba(255, 255, 255, 0.2);
    }
  }
}
.btnTitle {
  width: calc(100% - 90px);
  text-align: center;
  font-size: 16px;
}
.googleBtn {
  background: #d84830 !important;
}
.microsoftBtn {
  background: #1a78d6 !important;
}

@media (max-width: 767px) {
  .btnArea {
    flex-direction: column;
    gap: 20px;

    button {
      margin: 0 auto;
    }
  }
}
