.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  box-sizing: border-box !important;
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: #131b2467;
  box-sizing: border-box !important;
  z-index: 2000;
}

.slide {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 752px;
  min-height: 452px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 12px;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    min-width: 90%;
  }
}

.wrapper {
  padding: 80px;
  @media (max-width: 768px) {
    padding: 64px 12px;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.circleWrapper {
  width: 60px;
  height: 60px;
  position: relative;
  margin: auto;
}

.circle {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-mask-image: radial-gradient(transparent 60%, white 60%);
  mask-image: radial-gradient(transparent 60%, white 60%);
  border-radius: 50%;
  background: conic-gradient(#3ebb9d 0deg 180deg, #42464b 180deg 360deg);
}

.count {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #4a617c;

  &__current {
    font-size: 20px;
    font-weight: 700;
  }

  &__slash {
    width: 7px;
    height: 12px;
  }

  &__total {
    font-size: 14px;
    font-weight: 700;
  }
}

.question {
  font-weight: 700;
  text-align: center;

  &__label {
    font-size: 24px;
    line-height: 38.4px;
  }
}

.description {
  font-size: 16px;
  line-height: 25.6px;
  color: #333333;
  text-align: center;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;

  &__button {
    padding: 8px 16px;
    width: 272px;
    height: 58px;
    border: 1px solid #777e90;
    border-radius: 4px;
    background: #ffffff;
    font-size: 16px;
    line-height: 25.6px;
  }
}

.item {
  position: relative;

  &__icon_close {
    width: 16px;
    height: 16px;
  }

  &__icon_complete {
    width: 60px;
    height: 60px;
  }

  &__button_close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    border: 0;
    color: #777e90;
    background-color: transparent;
    cursor: pointer;
  }
}

:global(.slick-track) {
  display: flex;
}
