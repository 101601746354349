$roots: #3e9eff;
$will: #195cfb;
$exp: #e7c06c;

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border-radius: 2px;

  &.roots {
    background-color: $roots;
  }

  &.will {
    background-color: $will;
  }

  &.exp {
    background-color: $exp;
    background: linear-gradient(95.14deg, #f9d682 0%, #cea962 100%);
  }
}

.origin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 24px;
  font-size: 12px;
  color: #999;
  border-radius: 2px;
  border: solid 1px #999;
  box-sizing: border-box;
}
