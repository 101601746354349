$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

// @keyframes open {
//   from {
//     max-height: 0px;
//   }
//   to {
//     max-height: 210px;
//   }
// }

.close {
  height: 0px;
  overflow: hidden;
  transition: height 2s ease;
}

.boxArea {
  display: block;
  max-height: 210px;
  overflow: auto;
  --background: #131b24;
  // animation-duration: 0.3s;
  // animation-name: open;
  // -moz-animation-duration: 0.3s;
  // -moz-animation-name: open;
  // -webkit-animation-duration: 0.3s;
  // -webkit-animation-name: open;
}

.boxItemToggle {
  --background: #131b24;
  --border-color: #263547;
  cursor: pointer;

  &.second .title {
    font-size: 16px;
  }
}

.boxSelector {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;

  .boxItem {
    --background: #131b24;
    --border-color: #263547;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #263547;
    color: white;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;

    .boxName {
      width: 50%;
    }

    .teacherClassWrapper {
      display: flex;
      width: 35%;
      font-size: 14px;
      color: #98999b;
      flex-direction: column;

      .teacher {
        font-weight: bold;
      }
    }

    .checkIcon {
      flex: 1;
      height: 20px;
      width: 20px;
      color: #3dba9d;
      float: right;
      margin-right: 20px;
      margin-top: 1px;
    }
  }
}

.arrowIcon {
  color: #4e5154;
}

.selectedNames {
  color: #3dba9d;
  font-size: 15px;
}
