$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  --width: 100%;
  --height: 100%;
  --background: rgba(255, 255, 255, 0.5);
  .modalItems {
    width: 90%;
    max-width: 500px;
    height: 90%;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: $mainBackgroundColor;
    .label {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .inputArea {
      margin-right: 15px;
      margin-left: 15px;
      border: solid 1px #4e5154;
      border-radius: 5px;

      .input {
        --placeholder-color: #fff;
        --color: #fff;
        --padding-start: 8px;
        caret-color: #fff;
        color: #fff;
      }
    }
    .buttonArea {
      margin-top: 30px;
      text-align: center;
      .button {
        width: 45%;
      }
      .cancelButton {
        width: 45%;
        margin-right: 8px;
        --border-color: #565e67;
      }
    }
  }
}
