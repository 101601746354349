$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;

.wrapper {
  --offset-top: -44px !important;
  --background: #35435a;
  position: relative;

  &--white {
    --background: #f7f7f7;

    .container {
      background: #f7f7f7;
    }
  }
}
.subWrapper {
  --background: #1d2936;
}
.realizationWrapper {
  background-color: #35435a;
}
.scrollWrapper {
  background-color: #131b24;
  overflow: auto;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  background: #131b24;
  position: relative;
}
.boxIcon {
  height: 20px;
  width: 20px;
  padding: 4px;
  color: #4fcdde;
  cursor: pointer;
}

.filterIcon {
  height: 20px;
  width: 20px;
  padding: 4px;
  color: #fff;
  cursor: pointer;
}

.timelineIcon {
  height: 20px;
  width: 20px;
  padding: 4px;
  color: #3dba9d;
  cursor: pointer;
}
.button {
  transition: 0.3s;
}

.wrapperPast {
  --offset-top: -44px !important;
  --background: #484d52;

  .container {
    background: #484d52;
  }
}
