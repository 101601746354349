$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  --background: #131b24;
  background-color: #131b24;
}

.profileList {
  --background: #1d2936;
  background-color: #1d2936;
  * {
    --background: #1d2936;
  }
}

.postButton {
  margin: auto;
  margin-top: 10px;
  width: 50%;
}

.cancelButton {
  margin: auto;
  margin-top: 10px;
  width: 50%;
  --border-color: #565e67;
}
