.wrapper {
  display: flex;
  background-color: #00507d;
  background-image: url("../../assets/images/gearfes_back.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    background-position: right center;
  }
  @media (max-width: 520px) {
    background-image: url("../../assets/images/gearfes_back_sp.jpg");
    background-position: center center;
  }
  @media (max-width: 450px) {
    background-image: url("../../assets/images/gearfes_back_sp_small.jpg");
    background-position: center 28%;
  }
}

.innerWrapper {
  width: 100%;
  border: solid 1px #fff;
  padding: 16px;
  @media (max-width: 520px) {
    padding: 12px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 476px;
  margin-left: auto;
  margin-right: 24%;
  @media (max-width: 720px) {
    margin-left: calc(50% - 234px);
    width: auto;
    margin-right: auto;
  }
  @media (max-width: 520px) {
    margin-left: 0;
  }

  .topTitle {
    display: flex;
    column-gap: 8px;
    align-items: center;
    color: #002ea5;
    @media (max-width: 520px) {
      align-items: flex-start;
      flex-direction: column;
    }

    .fesTitle {
      font-size: 28px;
      font-weight: bold;
      @media (max-width: 720px) {
        font-size: 22px;
      }

      .bulbIcon {
        width: 28px;
        height: 28px;
        margin: 0 2px;
        @media (max-width: 720px) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .subTitle {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .contentsWrapper {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;

    .themelabel {
      color: #fff;
      text-shadow: 0 0 6px rgba(#000, 0.25);
      font-size: 16px;
      font-weight: bold;
      @media (max-width: 720px) {
        font-size: 12px;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .themeTitle {
        background-color: #fff;
        color: #002ea5;
        border-radius: 6px;
        padding: 8px 12px;
        width: fit-content;
        @media (max-width: 720px) {
          padding: 8px;
        }

        .subTitle {
          margin: 0;
          font-size: 13px;
          font-weight: bold;
          @media (max-width: 720px) {
            font-size: 10px;
          }
          @media (max-width: 400px) {
            letter-spacing: -1px;
          }
        }

        .mainTitle {
          display: flex;
          margin: 0;
          font-size: 19px;
          font-weight: bold;
          @media (max-width: 720px) {
            font-size: 13px;
          }
          @media (max-width: 450px) {
            flex-direction: column;
          }
        }
      }

      .announcement {
        display: flex;
        column-gap: 12px;
        color: #fff;
        text-shadow: 0 0 6px rgba(#000, 0.25);
        line-height: 1.5;
        font-size: 12px;
        font-weight: bold;
        @media (max-width: 520px) {
          flex-direction: column;
        }
        .extra {
          color: #d8ff00;
        }
      }

      .actionButtons {
        display: flex;
        align-items: center;
        gap: 12px;
        // @media (max-width: 520px) {
        //   align-items: flex-start;
        //   flex-direction: column;
        //   gap: 8px;
        // }

        // > .buttonsWrapper {
        //   display: flex;
        //   gap: 12px;
        //   @media (max-width: 520px) {
        //     gap: 8px;
        //   }
        // }

        button {
          white-space: nowrap;
          border: 0;
          outline: none;
          background-color: #e8fe6d;
          color: #002ea5;
          border-radius: 3px;
          padding: 6px 16px;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          @media (max-width: 720px) {
            font-size: 12px;
          }

          &:disabled {
            background-color: #999;
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
