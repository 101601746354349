.wrapper {
  --width: 100%;
  --height: 100%;
  --background: rgba(255, 255, 255, 0.5);
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  box-sizing: border-box !important;
}

.slide {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 500px;
  min-height: 452px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 12px;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    min-width: 90%;
  }
}

.content {
  padding: 48px 64px;
  @media (max-width: 768px) {
    padding: 12px 18px;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.circleWrapper {
  width: 60px;
  height: 60px;
  position: relative;
  margin: auto;
}

.circle {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-mask-image: radial-gradient(transparent 60%, white 60%);
  mask-image: radial-gradient(transparent 60%, white 60%);
  border-radius: 50%;
  background: conic-gradient(#3ebb9d 0deg 180deg, #42464b 180deg 360deg);
}

.count {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #4a617c;

  &__current {
    font-size: 14px;
    font-weight: 700;
  }

  &__slash {
    width: 7px;
    height: 12px;
  }

  &__total {
    font-size: 14px;
    font-weight: 700;
  }
}

.question {
  font-weight: 700;
  text-align: center;

  &__label {
    font-size: 20px;
    line-height: 32px;
  }

  &__help {
    margin-top: 8px;
    font-size: 14px;
    line-height: 23px;
    color: #3ebb9d;
    text-decoration-line: underline;
  }
}

.score {
  text-align: center;

  &__wrapper {
    display: inline-block;
  }

  &__check_group {
    display: inline-flex;
    justify-content: center;
    gap: 4px;
  }

  &__box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background: #e3e3e3;
    cursor: pointer;
    color: #777e90;
  }

  &__box_checked {
    background: #3ebb9d;
  }

  &__label_group {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: #333333;
  }
}

.reason {
  &__input {
    --padding-start: 16px;
    --padding-top: 16px;
    --padding-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;

  &__button {
    padding: 8px 16px;
    width: 272px;
    height: 58px;
    border: 1px solid #777e90;
    border-radius: 4px;
    background: #ffffff;
    font-size: 16px;
    line-height: 25.6px;

    &_first_prev {
      width: 160px;
      height: 37px;
      border: 1px solid #3ebb9d;
      color: #3ebb9d;
      background: #ffffff;
      font-size: 14px;
      line-height: 21px;
    }

    &_first_next {
      width: 160px;
      height: 37px;
      border: 1px solid #3ebb9d;
      background: #3ebb9d;
      color: #ffffff;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.item {
  position: relative;

  &__icon_close {
    width: 16px;
    height: 16px;
  }

  &__button_close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    border: 0;
    color: #777e90;
    background-color: transparent;
    cursor: pointer;
  }
}

:global(.slick-track) {
  display: flex;
}
