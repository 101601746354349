$roots: #3e9eff;
$will: #195cfb;
$exp: #e7c06c;

.iconCounterList {
  margin-top: 40px;
  display: flex;
  background-color: #131b24;
  justify-content: space-evenly;
}

.realizationNum {
  color: $roots;
}

.conversionNum {
  color: $will;
}

.actionNum {
  color: $exp;
}
