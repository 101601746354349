.experienceNos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;

  @media (max-width: 520px) {
    flex-direction: column;
  }
  @media (max-width: 470px) {
    padding-bottom: 8px;
  }

  .label {
    color: #fff;
    margin: 16px 20px 8px;
    min-width: 80px;
    font-size: 17px;
  }

  .checkBoxList {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-right: 24px;
    width: 668px; // 2 rows (5 + 2)
    @media (max-width: 900px) {
      width: 524px; // 2 rows (4 + 3)
    }
    @media (max-width: 720px) {
      width: 392px; // 3 rows
    }
    @media (max-width: 600px) {
      width: 260px; // 4 rows
    }
    @media (max-width: 520px) {
      margin-top: 8px;
      padding-left: 40px;
      width: 100%; // n rows
      box-sizing: border-box;
    }
    @media (max-width: 470px) {
      gap: 8px;
    }
    @media (max-width: 400px) {
      padding: 0 20px;
    }

    .checkBoxItem {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      border-radius: 5px;
      padding: 6px 8px;
      user-select: none;
      cursor: pointer;

      // PC Only
      @media (hover: hover) {
        &:hover {
          color: #fff !important;
        }
      }

      @media (max-width: 470px) {
        padding: 4px 6px;
      }

      .checkboxLabel {
        margin: 0;
        font-size: 12px !important;
        font-weight: bold;
      }
    }

    ion-checkbox {
      --size: 16px;
      --checkbox-background-checked: #ffffff;
      margin: 0 6px 0 0;
      --background: transparent;
      --background-checked: transparent;
      --border-color-checked: transparent;
      --checkmark-width: 2px;
      @media (max-width: 470px) {
        --size: 12px;
        margin-right: 4px;
      }
    }

    ion-checkbox::part(container) {
      border-radius: 3px;
      border: 2px solid #ffffff;
      @media (max-width: 470px) {
        border: 1px solid #fff;
      }
    }
  }
}
