$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;
$will: #195cfb;
$wrapperButtonHeight: 80px;
$footerHeight: 72px;

.wrapper {
  --width: 100%;
  --height: 100%;
  --background: rgba(255, 255, 255, 0.5);
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    height: calc(100% - $wrapperButtonHeight);
    margin: 0 auto;
    background: #131b24;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .textAreaWrapper {
    max-height: 250px;
    overflow-y: auto;
  }

  .inputArea {
    background-color: #2a3139;
    margin: 10px 10px 0px;
    border-radius: 5px;
  }

  .imgInputWrapper {
    display: flex;
    padding: 0 8px 8px;

    .realizationImg {
      display: flex;
      margin: 0 auto;
      position: relative;
      cursor: zoom-in;

      > button {
        padding: 0;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        color: #fff;
        background: #2a3139;
        cursor: pointer;
        outline: none;
        border: none;
        transition: 0.3s;
        position: absolute;
        top: 8px;
        right: 8px;
        box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2);
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
    > input {
      display: none;
    }
    > svg {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .wrapperButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: $wrapperButtonHeight;
    background-color: #19222b;
    position: fixed;
    bottom: $footerHeight;
    z-index: 1;

    .convertButton {
      max-width: 168px;
      min-width: 152px;
      width: 30%;
      height: 52px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      padding: 5px 15px 5px 10px;
      background: none;
      border: 2px solid;
      border-radius: 5px;
      margin: 0 9px;
      color: #fff;
      cursor: pointer;
      border-color: $will;

      &:disabled {
        pointer-events: none;
        opacity: 0.8;
      }

      .icon {
        height: 32px;
        width: 32px;
        margin: 8px 13px 8px 8px;
        color: $will;
      }

      span {
        margin: auto;
        line-height: 18px;
      }
    }
  }

  .counter {
    text-align: right;
    margin-right: 10px;
  }

  .originWrapper {
    margin: 20px 10px 20px 30px;
  }
}
