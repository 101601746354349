$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  background-color: #131b24;

  .item {
    --background: #131b24;
    --border-color: #263547;
    --detail-icon-color: #98999b;
    --padding-start: 8px;
  }
  .slideOption {
    --background: red;
    background-color: red;
  }
  .content {
    color: #fff;
  }
  .categoryLabel {
    font-size: 13px;
  }
  .date {
    text-align: right;
    color: #98999b !important;
    font-size: 13px;
  }
  .grid {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
