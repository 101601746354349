$will: #195cfb;

.wrapper {
  height: 100%;
  background-color: #131b24;
  --background: #131b24;
  overflow-y: auto;
  overflow-x: hidden;

  .realizationWrapper {
    border-bottom: solid 2px #223243;
  }

  .contentWrapper {
    display: flex;
  }
  .convertButtonArea {
    margin-top: 10px;
    padding: 20px 0 16px;
    border-top: solid 2px #223243;

    button {
      max-width: 168px;
      min-width: 152px;
      width: 30%;
      height: 52px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      padding: 5px 15px 5px 10px;
      background: none;
      border: 2px solid;
      border-radius: 5px;
      margin: 0 auto;
      color: #fff;
      cursor: pointer;

      .icon {
        margin-right: 5px;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.8;
      }

      &.convertButton {
        border-color: $will;

        .icon {
          height: 32px;
          width: 32px;
          margin: 8px 13px 8px 8px;
          color: $will;
        }
      }

      span {
        margin: auto;
        line-height: 18px;
      }
    }
  }

  .executionButton {
    margin: 20px auto 26px;
    width: 50%;
  }

  .btn_containers {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    .editIcon,
    .deleteIcon {
      width: 18px;
    }
  }

  .toast {
    --background: #3dba9d;
    --color: #fff;
  }
}
.header {
  background: #1d2936;
}
.toolbar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
  .title {
    --color: #fff;
  }
  .configButton {
    --color: #fff;
  }
}
