.container {
  width: 100%;
  height: calc(100% - 72px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box !important;
  overflow: hidden;
  .wrapper {
    width: 100%;
    height: calc(100% - 44px);
    margin-top: 44px;
    overflow: auto;
    &.bg-light {
      background-color: #f7f7f7;
    }

    &.bg-dark {
      background: #35435a;
    }
  }
  .content {
    height: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.header {
  z-index: 999;

  &--analyze {
    background: linear-gradient(to left, #1d2936, #1e2c40, #00489b) !important;
  }
}
