$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  background: rgba(255, 255, 255, 0.5);
  > div:last-child {
    background-color: transparent;
  }
  .form {
    min-width: 348px;
    max-width: 420px;
    width: 80%;
    height: 230px;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 1025;
    border-radius: 12px;
    background-color: $subBackgroundColor;
  }
  .item {
    --background: #1d2936;
    .label {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 15px;
      color: #fff !important;
      .title {
        margin-bottom: 24px;
        font-weight: bold;
      }
      > p {
        color: #fff !important;
      }
    }
    .buttonArea {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      text-align: center;
      border-top: solid 1px #363a3e;
      .cancelButton {
        width: 50%;
        color: #3dba9d;
        --color: #3dba9d;
        --background: #1d2936;
        --background-activated: #1d2936;
        background-color: #1d2936;
        border-right: solid 1px #363a3e;
      }
      .reportButton {
        width: 50%;
        color: red;
        --color: red;
        --background: #1d2936;
        --background-activated: #1d2936;
        background-color: #1d2936;
      }
    }
  }
}
