ion-popover {
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --width: 560px;
  --height: 240px;
  --offset-y: 0px;

  @media only screen and (max-width: 768px) {
    --width: calc(100% - 12px);
    --height: 230px;
    --offset-y: 0px;
  }
}

.content {
  --background: #fff;
}

.stampContainer {
  width: 76px;
  height: 76px;
}
