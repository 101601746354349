.modal {
  position: fixed;
  inset: 0;
  z-index: 2000;
}

.backdrop {
  width: 100%;
  height: 100%;
  background: #131b24;
  position: relative;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0;
    width: 34px;
    height: 34px;
    background-color: #2a3139;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    border: none;
    outline: none;
    appearance: none;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s;
    &:hover {
      opacity: 1;
    }

    .closeIcon {
      color: #fff;
      font-size: 26px;
    }
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
