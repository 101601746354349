.boxItem {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-end: 10px;
  --detail-icon-color: black;

  &.pointer {
    cursor: pointer;
  }

  .boxWrapper {
    width: 100%;

    .boxTopContent {
      display: flex;
      width: 100%;

      .boxIcon {
        flex-shrink: 0;
        width: 37px;
        height: 43px;
        margin: 0;
        color: #4fcdde;
      }

      .boxInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: calc(100% - 59px);
        padding: 12px 0 0 22px;

        .boxTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .label {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            b {
              background-color: #386ec6;
            }
          }

          .arrowIcon {
            margin-right: -5px;
            width: 20px;
            height: 20px;
          }
        }

        .boxAuthor {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          p {
            margin: 8px 0;
            font-size: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .realizationsCount {
            display: flex;
            align-items: center;
            color: #54779c;
            font-size: 14px;

            > span {
              margin-left: 4px;
            }
          }
        }
      }
    }

    .newestRealization {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-left: 28px;
      padding: 10px 24px;
      color: #1d2936;
      border: 1px solid #d0d0d0;
      border-radius: 6px;
      position: relative;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-left: 8px solid;
        position: absolute;
        top: -15px;
        left: 10px;
        z-index: 2;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-left: 10px solid;
        position: absolute;
        top: -18px;
        left: 9px;
        z-index: 1;
      }

      .avatarArea {
        &.clicableAvatar {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }

        .realizationAvatar {
          flex-shrink: 0;
          width: 46px;
          height: 46px;
        }
      }

      .realizationInfo {
        width: calc(100% - 46px);
        margin-left: 14px;
        user-select: none;

        > p {
          font-size: 15px;
          line-height: 1.2;

          &:first-child {
            margin: 0 0 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:last-child {
            margin: 0;
            display: inline-block;
            display: -webkit-box;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  &.light {
    --background: #fff;
    --border-color: #d0d0d0;

    .boxTitle {
      .label {
        color: #1d2936;
      }
    }

    .boxAuthor {
      p {
        color: rgba(#1d2936, 0.58);
      }
    }

    .newestRealization {
      color: #1d2936;
      border: 1px solid #d0d0d0;

      &::before {
        border-left-color: #fff;
      }

      &::after {
        border-left-color: #d0d0d0;
      }
    }
  }

  &.dark {
    --background: #131b24;
    --border-color: #1d2936;

    .boxTitle {
      .label {
        color: #fff;
      }
    }

    .boxAuthor {
      p {
        color: rgba(#fff, 0.58);
      }
    }

    .newestRealization {
      color: #fff;
      border: 1px solid #324152;

      &::before {
        border-left-color: #131b24;
      }

      &::after {
        border-left-color: #324152;
      }
    }
  }
}
