$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  display: flex;
  flex: 1;
  box-sizing: border-box;
  .colorArea {
    width: 10px;
  }
  .colorTip {
    width: 3px;
    height: 100%;
    margin-top: 2px;
    margin-right: 10px;
  }
  .labelArea {
    display: flex;
    color: #98999b;
    width: 100%;
  }
  .light {
    color: #fff;
  }
  .label {
    display: -webkit-box;
    word-wrap: anywhere;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .count {
    margin-left: 4px;
    color: #98999b;
  }
  .fontS {
    font-size: 12px;
  }
  &:not(:last-child) {
    padding-right: 10px;
    margin-bottom: 3px;
  }
}
.pointer {
  cursor: pointer;
}
