.listSegment {
  height: 50px;
  flex-direction: row;

  &.flexRowReverse {
    flex-direction: row-reverse;
  }

  .segmentButton {
    width: 50%;
    --indicator-color: #3dba9d;
    --indicator-height: 3px;
    --color: #777;
    --color-checked: #3dba9d;

    .segmentLabel {
      font-size: 13px;
      position: relative;

      .feedbackBadge {
        position: absolute;
        bottom: 12px;
        left: 164px;
        height: 10px;
        --padding-start: 0px;
        --padding-end: 0px;
        --padding-top: 0px;
        --padding-bottom: 0px;
      }

      .commentBadge {
        position: absolute;
        bottom: 12px;
        height: 10px;
        --padding-start: 0px;
        --padding-end: 0px;
        --padding-top: 0px;
        --padding-bottom: 0px;
      }
    }
  }
}

.listWrapper {
  background-color: #131b24;
  --background: #131b24;

  .inputArea {
    display: flex;
    align-items: center;
    width: 95%;
    margin: 10px auto;
    border-radius: 30px;
    border: solid 2px #323538;
    background-color: #21272e;

    .input {
      padding-top: 11px;
      margin-left: 15px;
      --placeholder-color: #fff;
      caret-color: #3dba9d;
      color: #fff;
      display: block;
      width: 100%;
      max-width: 100%;
      resize: none;
      border: 0;
      outline: none;
      background: transparent;
      box-sizing: border-box;
      appearance: none;
    }

    .cancelButton {
      width: 60px;
      font-size: 12px;
      margin-top: 10px;
      --background: #21272e;
      --background-activated: #21272e;
      --background-opacity: 1.2;
      --color: #3dba9d;
    }

    .commentButton {
      margin-bottom: 2px;
      vertical-align: middle;
      margin-right: 10px;
      --background: #21272e;
      --background-activated: #21272e;
      --background-opacity: 1.2;
      --color: #3dba9d;
    }
  }

  .commentList {
    padding-bottom: 100px;
  }

  .container {
    width: 100%;
    max-width: 1000px;
    min-height: 68px;
    position: fixed;
    bottom: 0;
    z-index: 1;
    background-color: #131b24;
    background: linear-gradient(
      rgba(19, 27, 36, 0.9) 0%,
      #131b24 5%,
      #131b24 100%
    );

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 16px;
      position: absolute;
      margin-top: -16px;
      background: transparent;
      background: linear-gradient(
        rgba(19, 27, 36, 0) 0%,
        rgba(19, 27, 36, 0.9) 100%
      );
    }

    &.hasTab {
      bottom: 72px;
    }
  }
}

.wrapperLight {
  background-color: #fff;

  .container {
    position: fixed;
    bottom: 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0.9) 0%,
      #fff 5%,
      #fff 100%
    );

    &::before {
      background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.9) 100%
      );
    }

    &.hasTab {
      bottom: 72px;
    }
  }
}
