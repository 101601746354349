.wrapper {
  background-color: #1d2936;
  width: 100%;
  height: 100vh;
  height: 100dvh;

  .content {
    padding: 40px;
    color: #fff;

    a {
      color: #fff;
    }
  }
}
