.datePickerWrapper {
  height: max-content;
  position: relative;
  z-index: 10;

  &.primary {
    .datePickerInput {
      padding: 6px 2px 6px 8px;
      background: #eaeaea;
      box-shadow: inset 1px 4px 6px rgba(#000, 0.1);
      border-radius: 3px;

      .label {
        color: #1e2c3f;
      }
    }
  }

  &.secondary {
    .datePickerInput {
      box-sizing: border-box;
      padding: 8px 12px;
      border: 1px solid #e3e3e3;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;

      .label {
        color: #777e90;
      }
    }
  }

  .datePickerInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;
    position: relative;

    .label {
      margin-top: -2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .arrowIcon {
      height: 18px;
      width: 20px;
    }
  }

  .errorMessage {
    margin: 2px 0 0;
    color: red;
    font-size: 10px;
  }

  .datePickerHeader {
    max-width: 210px;
    margin: 0 auto;

    .datePickerPickedDate {
      margin-bottom: 14px;
      margin-top: -2px;
      color: #fff;
      font-size: 12px;
    }

    .datePickerInputGroup {
      display: flex;
      gap: 0 4px;
      margin: 0 10px 6px;

      > input {
        width: calc(50% - 2px);
        height: 18px;
        font-size: 11px !important;
        border-radius: 3px;
        border: 0;
        outline: 0;
        box-sizing: border-box;

        &::placeholder {
          font-size: 11px;
        }

        &:focus-visible {
          outline: 1px solid #3880ff;
        }
      }
    }

    .invalidDate {
      margin: 0 10px;
      font-size: 10px;
      color: #d84429;
    }

    .datePickerHeaderActions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #fff;

      > button {
        background: transparent;
        border: 0 !important;
        outline: 0 !important;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &.active.primary {
    .datePickerInput {
      color: #3880ff;
      outline: 2px solid #3880ff;
      border-radius: 3px;

      .label {
        color: #3880ff;
      }
    }
  }

  &.error.primary {
    .datePickerInput {
      color: red;
      outline: 2px solid red;
      border-radius: 3px;

      .label {
        color: red;
      }
    }
  }
}
