$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;
$optionHighlightColor: #98999b;

.wrapper {
  display: flex;
  flex: 1 1;
  align-items: center;
  height: 50px;
  padding: 0 10px 0 20px;
  border-top: 1px solid #2b3c50;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid #2b3c50;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: #93f7ff;
  }
  .labelArea {
    display: flex;
    align-items: center;
    width: 95%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #fff;

    b {
      background-color: #386ec6;
    }

    .count {
      margin-left: 4px;
      color: #98999b;
    }
  }
  .label {
    word-wrap: anywhere;
  }
  .arrowIcon {
    margin-left: auto;
    width: 20px;
    height: 20px;
    fill: #98999b;
    opacity: 0.25;
  }
}
