$breakPoint: 1000px;
$breakPointMiddle: 768px;
$breakPointSmall: 450px;

.list {
  list-style-type: none;
  margin: 0;
  padding: 0 24px 24px;
}

.item {
  border-bottom: solid 1px #36435a;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 80px;
  @media (max-width: $breakPointMiddle) {
    padding: 24px 0;
  }
}

.date {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.content {
  margin: 0;
  white-space: pre-wrap;
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
}
