.assessmentStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px 15px 20px 15px;
  width: calc((100% - 40px) / 3);
  min-height: 96px;
  border: 1px solid #4a617c;
  border-radius: 4px;
  background-color: #172029;
  color: #fff;
  cursor: pointer;
  @media (max-width: 768px) {
    align-items: flex-start;
    padding: 15xpx 46px 15px 40px;
    width: 100%;
    min-height: 78px;
  }

  &.isDisable {
    background-color: #3ebb9d;
    opacity: 0.3;
    pointer-events: none;
  }

  * {
    margin: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .desc {
    font-size: 20px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .status {
    width: 24px;
    height: 24px;
  }
}
