$roots: #3e9eff;
$will: #195cfb;
$exp: #e7c06c;

.container {
  .headerContent {
    margin-top: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 2px 2px 10px #00000070;

    @media (max-width: 768px) {
      padding: 25px;
    }

    .wrapperTopInfo {
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        .infoStudent {
          padding-left: 50px !important;

          .avatar {
            width: 36px !important;
            height: 36px !important;
          }
        }

        .selectorDate {
          width: 100% !important;
        }
      }

      .infoStudent {
        display: flex;
        align-items: center;
        gap: 20px;
        font-weight: bold;
        padding-left: 120px;
        position: relative;

        .avatar {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 50%;
          position: absolute;
          left: 0;
        }
      }

      .selectorDate {
        width: 200px;
        height: 30px;
        margin: 15px 0 15px auto;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .wrapperParameter {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .blockLeft {
      width: 50%;

      @media only screen and (max-width: 768px) {
        width: 100%;

        .actionCountGroup {
          margin-left: 20%;
          grid-gap: 0 !important;

          .actionCount {
            flex-direction: column;
            width: 33%;
          }
        }
      }

      .wrapperChart {
        display: flex;
        padding: 50px 0 30px;
        position: relative;

        @media only screen and (max-width: 768px) {
          padding: 0;

          .realizationsCount {
            width: 20% !important;
            height: 60px;
            position: absolute;
            top: 85px;
          }
        }

        .realizationsCount {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100px;
          background-color: #eaf2ff;
          color: #3880ff;

          p {
            font-weight: bold;
            margin: 0;

            &:first-child {
              font-size: 11px;
            }

            &:last-child {
              font-size: 16px;
            }
          }
        }

        .realizationAnalysis {
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 2;
          width: 100%;
          gap: 4px;
          padding: 10px 10px;
          box-sizing: border-box;

          @media (max-width: 768px) {
            padding: 0;
          }

          .analysisItem {
            display: flex;
            width: calc(100% - 34px);
            height: 20px;
            font-size: 10px;
            font-weight: bold;
            line-height: 1;
            position: relative;
            color: #fff;
            -webkit-font-smoothing: antialiased;

            > span {
              color: #000;
            }

            &::before,
            > span {
              padding-left: 4px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }

            > div {
              min-width: 2px;
            }
          }

          .root {
            &::before {
              content: "ROOTS";
              text-shadow: $roots 0px 0px 1px, $roots 0px 0px 1px,
                $roots 0px 0px 1px, $roots 0px 0px 1px, $roots 0px 0px 1px,
                $roots 0px 0px 1px;
            }

            > div {
              background-color: $roots;
            }
          }

          .will {
            &::before {
              content: "WILL";
              text-shadow: $will 0px 0px 1px, $will 0px 0px 1px,
                $will 0px 0px 1px, $will 0px 0px 1px, $will 0px 0px 1px,
                $will 0px 0px 1px;
            }

            > div {
              background-color: $will;
            }
          }

          .exp {
            &::before {
              content: "EXP";
              text-shadow: $exp 0px 0px 1px, $exp 0px 0px 1px, $exp 0px 0px 1px,
                $exp 0px 0px 1px, $exp 0px 0px 1px, $exp 0px 0px 1px;
            }

            > div {
              background-color: $exp;
              background: linear-gradient(95.14deg, #f9d682 0%, #cea962 100%);
            }
          }
        }
      }

      .actionCountGroup {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 10px 0;
        box-sizing: border-box;
        gap: 8px;

        @media (max-width: 768px) {
          padding: 10px 0 20px;

          .actionCount {
            font-size: 8px !important;
          }
        }

        .actionCount {
          display: flex;
          align-items: center;
          gap: 4px;
          color: rgba(4, 32, 108, 0.5);
          font-size: 10px;
          text-align: center;

          > p {
            word-break: keep-all;
          }

          > div {
            width: 45px;
            height: 30px;
            padding-top: 3px;
            text-align: center;
            color: #04206c;
            font-size: 14px;
            font-weight: bold;
            box-sizing: border-box;
          }

          &:first-child {
            > div {
              background: url("../../assets/icons/favorite.svg") center center /
                contain no-repeat;
            }
          }

          &:nth-child(2) {
            > div {
              background: url("../../assets/icons/comment.svg") center center /
                contain no-repeat;
            }
          }
        }
      }
    }

    .chartContainer {
      position: relative;
      width: 50%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      svg {
        position: relative;
        width: 100%;
      }

      &::before {
        content: "";
        width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #eaeaea;
      }
    }
  }
}
