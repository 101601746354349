.wrapper {
  background-color: #131b24;

  .boxInfo {
    --border-color: #2b3e53;
    --border-width: 0 0 3px 0;
  }

  .loadMore {
    padding: 5px 0 10px;
    color: #fff;
    text-align: center;
  }
}
