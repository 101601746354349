.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  box-sizing: border-box !important;
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: #131b2467;
  box-sizing: border-box !important;
  z-index: 2000;
}

.slide {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80%;
  min-width: 337px;
  max-width: 487px;
  height: 623px;
  background: #131b24;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  border: 1px solid #fff;
}

.item {
  position: relative;

  .image {
    height: 623px;
    background-size: 487px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__icon_control {
    width: 25px;
    height: 25px;
  }

  &__icon_close {
    width: 18px;
    height: 18px;
  }

  &__button_next {
    position: absolute;
    bottom: 42px;
    left: 50%;
    padding: 0;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    transform: translateX(150%);

    &.turquoise {
      color: #4fcdde;
    }

    &.dodger_blue {
      color: #3880ff;
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  &__button_prev {
    position: absolute;
    bottom: 42px;
    left: 50%;
    padding: 0;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    transform: translateX(-250%);

    &.turquoise {
      color: #4fcdde;
    }

    &.dodger_blue {
      color: #3880ff;
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  &__button_close {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0;
    border: 0;
    color: #fff;
    background-color: transparent;
    cursor: pointer;

    &.black_light {
      color: #2c2c3e;
    }
  }

  &__button_finish {
    position: absolute;
    left: 50%;
    top: 481px;
    padding: 4px 13px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    border-radius: 21px;
    border: 1.5px solid #fff;
    background-color: #00000069;
    transform: translateX(-50%);
    cursor: pointer;
  }
}

:global(.slick-track) {
  display: flex;
}
