.assessmentComment {
  textarea {
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    height: 167px;
    border-radius: 5px;
    background: #243140;
    color: #ffffff;
    border: none;
    resize: none;
    &::placeholder {
      color: #4a617c;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
