$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.inactive {
  color: #777;
}
.segment {
  margin-top: 30px;
  --background: #212633;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.segmentButton {
  --color: #fff;
  --indicator-color: #3dba9d;
  --background-checked: #3dba9d;
}
