$subBackgroundColor: #1d2936;

.wrapper {
  --width: 100%;
  --height: 100%;
  --background: rgba(255, 255, 255, 0.5);
  --border-radius: 0;

  .modal {
    width: 90%;
    max-width: 480px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%);
    border-radius: 12px;
    background-color: $subBackgroundColor;
    overflow: hidden;
    padding: 24px 0;
  }

  .title {
    display: block;
    text-align: center;
    color: #fff;
  }

  .filterList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 16px;
    margin-top: 24px;

    .item {
      display: flex;
      align-items: center;

      input {
        width: 18px;
        height: 18px;
      }

      label {
        margin-left: 8px;
        color: #fff;
      }
    }
  }

  .buttonArea {
    margin-top: 24px;
    text-align: center;
    .button {
      width: 45%;
    }
    .cancelButton {
      width: 45%;
      margin-right: 8px;
      --border-color: #565e67;
    }
  }
}
