$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

// @keyframes open {
//   from {
//     max-height: 0px;
//   }
//   to {
//     max-height: 210px;
//   }
// }
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.close {
  display: none;
}
.open {
  display: block;
}
.classArea {
  display: block;
  max-height: 210px;
  overflow: auto;
  --background: #131b24;
  background-color: #131b24;
  // animation-duration: 0.3s;
  // animation-name: open;
  // -moz-animation-duration: 0.3s;
  // -moz-animation-name: open;
  // -webkit-animation-duration: 0.3s;
  // -webkit-animation-name: open;
}
.classItemToggle {
  --background: #131b24;
  --border-color: #263547;
  cursor: pointer;

  &.second .title {
    font-size: 16px;
  }
}
.airplaneIcon {
  margin-right: 7px;
  font-size: 20px;
}
.arrowIcon {
  color: #4e5154;
}
.classSelector {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;

  .classItem {
    --background: #131b24;
    --border-color: #263547;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #263547;
    cursor: pointer;
  }
  .clsName {
    color: #fff;
    .checkIcon {
      height: 20px;
      width: 20px;
      color: #3dba9d;
      float: right;
      margin-right: 20px;
      margin-top: 1px;
    }
    .submittedText {
      color: #515457;
      float: right;
      margin-right: 20px;
    }
  }
}
.selectedNames {
  color: #3dba9d;
  font-size: 15px;
}
