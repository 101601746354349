$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.wrapper {
  max-width: 500px;
  margin: 0 auto;
  background-color: #1d2936;
  .topTitle {
    text-align: center;
    margin-top: 60px;
    color: #fff;
  }

  .inputArea {
    margin-right: 40px;
    margin-left: 40px;
    .input {
      --background: #fff;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  .loginButton {
    margin: auto;
    margin-top: 30px;
    --background: #3dba9d;
    --background-activated: #3dba9d;
    color: #fff;
    font-weight: bold;
    width: 80%;
  }

  .text {
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
  }
}

.invalidFeedback {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #d84429;
}
