.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  &.primary {
    font-size: 12px;
    font-weight: bold;
    color: #1e2c3f;

    .labelArea {
      padding: 6px 2px 6px 8px;
      background: #eaeaea;
      box-shadow: inset 1px 4px 6px rgba(#000, 0.1);
      border-radius: 3px;

      .arrowIcon {
        height: 18px;
        width: 20px;
      }
    }
  }

  &.secondary {
    .labelArea {
      display: flex;
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      padding: 8px 12px;
      background-color: #fff;
      color: #777e90;
      font-size: 14px;
      font-weight: 400;
    }
    &.isOpen {
      .labelArea {
        border: none;
      }

      .arrowIcon {
        transform: rotate(180deg);
      }
    }
  }

  .labelArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;

    .label {
      flex: 1;
      margin-top: -2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .menu {
    width: 100%;
    height: max-content;
    margin: 0 auto;
    padding: 0 4px;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    user-select: none;
    cursor: pointer;
  }

  .option {
    padding: 6px 4px;
    border-top: 1px solid rgba(#fff, 0.4);
    color: #fff;
    overflow-wrap: break-word;
  }

  .active.primary {
    color: #3880ff !important;
  }

  &.active.primary {
    outline: 2px solid #3880ff;
    color: #3880ff;
    border-radius: 3px;
  }

  &.isOpen {
    color: #fff;
    z-index: 50;
    outline: 0;

    .labelArea {
      background: rgba(#2b3c50, 0.8);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: #fff;
    }

    .menu {
      background: rgba(#2b3c50, 0.8);
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
