.commentConfigArea {
  display: flex;
  margin-bottom: 5px;
  margin-left: 35%;
  width: 63%;
  min-height: 30px;
  background-color: #31445c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: solid 1px #4d7197;
  .button {
    width: 33%;
    height: 25px;
    font-size: 14px;
    --background: #31445c;
    --background-activated: #31445c;
    --background-activated-opcity: 1.2;
  }
  .border {
    border-right: solid 1px#4d7197;
    border-left: solid 1px #4d7197;
  }
}
