.rootWrapper {
  display: flex;

  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-top: 4px;
  }

  .content {
    width: 100%;
    padding: 8px;
    border: solid 1px #999;
    border-radius: 4px;
    overflow-wrap: anywhere;

    .text {
      margin: 8px 0 0;
      font-size: 14px;
      color: #999;
      white-space: pre-line;

      &.listItem {
        max-height: 72px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      &.deleted {
        margin: 0;
      }
    }
  }
}
