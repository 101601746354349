.item {
  --background: #131b24;
  --border-color: #263547;
  --detail-icon-color: #98999b;
  --padding-start: 8px;
  cursor: pointer;
  user-select: none;
}

.slideOption {
  --background: red;
  background-color: red;
}

.wrapperContent {
  display: flex;
  align-items: center;

  .iconCaretRight {
    margin-left: auto;
    width: 20px;
    height: 20px;
  }
}

.grid {
  margin-top: 6px;
}
