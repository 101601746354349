.label {
  margin-top: 15px;
  margin-left: 12px;
}

.checkboxWrapper {
  text-align: center;
  .checkbox {
    --size: 40px;
    margin-right: 12px;
    margin-top: 15px;
  }
}
