$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

.header {
  background: #1d2936;
  position: fixed;
}

.toolbar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
  .title {
    --color: #fff;
  }
  .menuIcon {
    color: #fff;
  }
  .clearButton {
    --color: #3dba9d;
  }
}

.searchArea {
  display: flex;
  align-items: center;
  position: relative;
}

.searchBox {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 0px;
  width: calc(100% - 136px);
  height: 30px;
  margin-left: 8px;
  padding-left: 8px;
  padding-right: 40px;
  &:focus {
    outline: none;
  }
}

.cross {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 56px;
  color: #000;
  cursor: pointer;
}

.searchBtn {
  height: 32px;
  width: 40px;
  cursor: pointer;
  background-color: #3dba9d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  .searchIcon {
    color: #fff;
    width: 20px;
    height: 20px;
  }
}

.cancelBtn {
  color: #fff;
  cursor: pointer;
  padding: 5px;
  width: 24px;
  height: 24px;
}

.icon {
  color: #fff;
  margin: 10px;
  cursor: pointer;
}

.iconChecked {
  color: #3dba9d;
  margin: 10px;
  cursor: pointer;
}

.searchField {
  margin-top: 10px;
}

.searchPosition {
  margin-top: 20px;
  margin-left: 10px;
}

.mark {
  position: absolute;
  top: 5px;
  left: 20px;
  font-size: 10px;
  color: #eb445a;
}
