.modal {
  width: 100%;
  height: 100%;
  position: static;
  top: 0;
  left: 0;
  z-index: auto;
  box-sizing: border-box !important;
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: #131b2467;
  box-sizing: border-box !important;
  z-index: 2000;
}

.slide {
  position: absolute;
  right: 40px;
  bottom: 192px;
  width: 299px;
  max-width: 299px;
  height: 130px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 12px;
}

.item {
  position: relative;

  &__icon_close {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &__label {
    position: absolute;
    top: 20px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
  }

  &__button_close {
    position: absolute;
    top: 10px;
    right: 12px;
    padding: 0;
    border: 0;
    color: #777e90;
    background-color: transparent;
    cursor: pointer;
  }
}

.bottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  &__button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background: #16bab6;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
}

:global(.slick-track) {
  display: flex;
}
