.assessmentComparison {
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  padding: 30px 30px 22px;
  border-radius: 5px;
  background-color: #111417;
  @media (max-width: 768px) {
    gap: 22px;
    padding: 30px 20px 30px;
  }
  .title {
    color: #4a617c;
    font-size: 17px;
    font-weight: 500;
  }
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    @media (max-width: 768px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 22px;
    }
  }
  .chart {
    width: 100%;
  }
  .labelList {
    display: flex;
    flex-direction: column;
    gap: 6px;
    list-style: none;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 6px 25px;
      height: calc((18px * 3) + (6px * 2));
    }
    .label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      .disc {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}
