.header {
  background: #1d2936;
}
.toolBar {
  max-width: 1000px;
  margin: 0 auto;
  --background: transparent;
  .title {
    --color: #fff;
  }
  .editButton {
    --color: #3dba9d;
  }
}
.categories {
  --background: #1d2936;
  --detail-icon-color: #98999b;
  --border-color: #263547;
  .reorderIcon {
    color: #fff;
  }
  .removeIcon {
    color: red;
    cursor: pointer;
  }
  .deleteSlide {
    --background: #f00;
    --color: #f00;
  }
}
.categoryItem {
  flex-shrink: 0;
  --background: #1d2936;
  --border-color: #263547;
  margin-top: 15px;
  margin-bottom: 15px;
  .addIcon {
    color: #3dba9d;
    cursor: pointer;
  }
}
