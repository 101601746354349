body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --ion-color-primary: #3dba9d;
  --ion-color-primary-rgb: 61, 186, 157;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;

  --ion-color-secondary: #3880ff;
  --ion-color-secondary-rgb: 56, 128, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-font-family: 'Noto Sans JP', sans-serif;

  --ion-color-support: #1d2936;
  --ion-color-support-rgb: 29, 41, 54;
  --ion-color-support-contrast: #ffffff;
  --ion-color-support-contrast-rgb: 255, 255, 255;

  --color-gray: #98999b;
}

.ion-color-support {
  --ion-color-base: var(--ion-color-support);
  --ion-color-base-rgb: var(--ion-color-support-rgb);
  --ion-color-contrast: var(--ion-color-support-contrast);
  --ion-color-contrast-rgb: var(--ion-color-support-contrast-rgb);
}

.button-outline {
  height: 36px;
  --color: #fff;
  --border-width: 2px;
  --border-radius: 5px;
}

button {
  font-family: 'Noto Sans JP', sans-serif;
}