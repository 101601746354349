$mainBackgroundColor: #131b24;
$subBackgroundColor: #1d2936;
$optionColor: #3dba9d;

@keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 210px;
  }
}

.close {
  display: none;
}
.open {
  display: block;
}
.categoryArea {
  display: block;
  max-height: 210px;
  overflow: auto;
  --background: #131b24;
  animation-duration: 0.3s;
  animation-name: open;
  -moz-animation-duration: 0.3s;
  -moz-animation-name: open;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-name: open;
  .categorySelector {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    .categoryItem {
      margin-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: solid 1px #263547;
      cursor: pointer;
    }
    .categoryName {
      display: flex;
      align-items: center;
      color: #fff;
      .checkIcon {
        height: 20px;
        width: 20px;
        color: #3dba9d;
        margin-right: 20px;
        margin-left: 10px;
        &.hide {
          opacity: 0;
        }
      }
    }
  }
}
.categoryItemToggle {
  --background: #131b24;
  --border-color: #263547;
  cursor: pointer;
}
.categoryItem {
  --background: #131b24;
  --border-color: #263547;
  margin-left: 30px;
}
.categoryIcon {
  margin-right: 7px;
  font-size: 20px;
}
.addIcon {
  color: #3dba9d;
  cursor: pointer;
}
.arrowIcon {
  color: #4e5154;
}
.radioButton {
  --color-checked: #3dba9d;
}
.selectedName {
  flex: 1;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #3dba9d;
  font-size: 15px;
}
