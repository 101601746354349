.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 520px) {
    flex-direction: column;
  }

  .label {
    color: #fff;
    margin: 16px 20px 8px;
    min-width: 80px;
    font-size: 17px;
  }
}

.targetSwitch {
  padding: 16px 24px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px 16px;
  justify-content: flex-end;

  @media (max-width: 720px) {
    gap: 4px 0;
    width: 320px;
  }
  @media (max-width: 520px) {
    padding: 0 24px 8px 40px;
    width: 100%;
    box-sizing: border-box;
  }

  .targetSwitchItem {
    color: #fff;
    margin-bottom: 2px;

    @media (max-width: 720px) {
      width: 160px;
    }
    @media (max-width: 520px) {
      width: 50%;
    }

    > input {
      margin: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.3em;
      vertical-align: top;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      border: 1px solid rgba(0, 0, 0, 0.25);
      appearance: none;
      border-radius: 50%;
      background-color: #fff;
      position: relative;
      cursor: pointer;

      &:focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }

      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;

        &:before {
          display: block;
          content: "";
          width: 7px;
          height: 7px;
          position: absolute;
          background: #fff;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    > label {
      padding-left: 8px;
      color: #fff;
      cursor: pointer;
    }
  }
}

.emptyItem {
  height: 8px;
  margin-left: 20px;
  border-bottom: 1px solid #263547;
}

.targetSelectWrapper {
  padding-left: 16px;
}
